/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Modal from '@material-ui/core/Modal'
import idx from 'idx'
import { RichText } from 'prismic-reactjs'
import { ModalWrapper, CloseIcon } from './Feature'
import GatsbyLink from '../../utils/GatsbyLink'
import Text from '../common/Text'
import Space from '../common/Space'
import Button from '../common/Button'
import { theme } from '../../styles'
import VimeoIframe from '../common/VimeoIframe'
import getImage from '../../utils/getImage'
import violetCheckMarkIcon from '../../../static/icons/check-icons/violet-check-icon.svg'
import CrossIcon from '../../../static/icons/misc-icons/close-icon.svg'
import toTitleCase from '../../utils/toTitleCase'
import LottieAnimation from '../LottieAnimation'

const BannerContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;

  &:after {
    ${p =>
      p.backgroundColor &&
      css`
        position: absolute;
        content: '';
        top: 0;
        z-index: -1;
        background-color: ${p.backgroundColor ? theme.color[p.backgroundColor] : theme.color.white};
        width: 500vw;
        margin-left: -300%;
        height: 100%;
      `}
  }
`

const BannerWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 48px;
  justify-content: space-between;
  justify-items: center;
  padding: 80px 0px;
  padding-top: ${p => (p.hasTopPadding ? '64px' : '0px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '64px' : '0px')};

  @media (max-width: 1500px) {
    padding: 32px 0px;
    padding-top: ${p => (p.hasTopPadding ? '32px' : '0px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '48px' : '0px')};
  }

  @media (max-width: 1300px) {
    padding: 24px 0px;
    padding-top: ${p => (p.hasTopPadding ? '24px' : '0px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '24px' : '0px')};
  }

  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
    grid-gap: 32px;
    padding: 32px 0px 20px 0px;
    padding-top: ${p => (p.hasTopPadding ? '32px' : '0px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '20px' : '0px')};
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  width: 100%;

  @media (max-width: 1050px) {
    min-width: unset;
    align-self: center;
    justify-content: flex-start;
    align-items: center;

    * {
      margin: 0 auto;
      text-align: center !important;
    }
  }

  @media (max-width: 800px) {
    h1 {
      font-size: 2.33rem !important;
    }
    h2 {
      font-size: ${theme.fonts.size.heading2}!important;
    }
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1500px) {
    justify-content: flex-start;
    max-width: 600px;
  }

  @media (max-width: 1050px) {
    max-width: 80vw;
    margin: 0 auto;
  }

  @media (max-width: 800px) {
    display: ${p => (p.showImgMob ? 'block' : 'none')};
  }
`

const TitleText = styled(Text)`
  opacity: 0;
  opacity: ${p => (p.dkiActive || p.dkiLoaded) && 1};
  transition: all 75ms ease-in-out;

  @media (max-width: 800px) {
    h1 {
      width: calc(100% + 12px);
      margin-left: -6px;
    }
  }
`

const BannerText = styled(Text)`
  li {
    font-size: 18px !important;
  }
  @media (max-width: 800px) {
    p,
    li {
      font-size: 16px !important;
    }
    p {
      font-size: ${theme.fonts.size.largeBody}!important;
    }
  }
`

const CheckMarkListWrapper = styled(Text)`
  opacity: 0;
  opacity: ${p => (p.dkiActive || p.dkiLoaded) && 1};
  transition: all 75ms ease-in-out;
  ul {
    margin-left: 20px;
  }
  li {
    padding-inline-start: 12px;
    list-style-type: none;
    list-style-image: url(${violetCheckMarkIcon});

    @media (max-width: 1050px) {
      text-align: left;
    }

    @media (max-width: 800px) {
      text-align: left !important;
      margin-bottom: 16px;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  .button.primary,
  .button.secondary {
    min-width: 200px;

    @media (max-width: 1050px) {
      margin: 0px 8px;
    }

    @media (max-width: 800px) {
      margin: 0 auto;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
  }
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;

  @media (max-width: 1050px) {
    margin: 0 auto 20px auto;
  }

  @media (max-width: 800px) {
    display: ${p => (p.showImgMob ? 'block' : 'none')};
  }
`

const LottieWrapper = styled.div`
  object-fit: cover;
  width: 100%;

  @media (max-width: 1050px) {
    margin: 0 auto 20px auto;
  }

  @media (max-width: 800px) {
    display: ${p => (p.showImgMob ? 'block' : 'none')};
  }
`

const BannerTitle = ({ primary }) => {
  const text = <RichText render={primary.text.richText} serializeHyperlink={GatsbyLink} />
  const smallCapsText = <RichText render={primary.small_caps_text.richText} serializeHyperlink={GatsbyLink} />
  const imageSrc = idx(primary, _ => _.image.url)
  const hasTopPadding = idx(primary, _ => _.has_top_padding)
  const hasBottomPadding = idx(primary, _ => _.has_bottom_padding)
  const backgroundColor = idx(primary, _ => _.background_color)
  const modalVideo = idx(primary, _ => _.modal_video.url)
  const primaryButtonText = idx(primary, _ => _.primary_button_text.text)
  const primaryButtonLink = idx(primary, _ => _.primary_button_link.url) || idx(primary, _ => _.primary_button_link.uid)
  const secondaryButtonText = idx(primary, _ => _.secondary_button_text.text)
  const secondaryButtonLink =
    idx(primary, _ => _.secondary_button_link.url) || idx(primary, _ => _.secondary_button_link.uid)
  const noShadow = true
  const anchorId = primary.anchor_id
  const showImgMob = primary.show_img_mobile
  const rawLottie = primary.animation && primary.animation.text
  const lottieAnim = rawLottie ? JSON.parse(primary.animation.text) : null
  const lottieAriaLabel = idx(primary, _ => _.animation_aria_label)
  let dkiHeading = primary.title.richText && primary.title.richText[0] && primary.title.richText[0].text

  const [open, setOpen] = useState(false)
  const [newHeading, setNewHeading] = useState(null)
  const [newCheckMarkList, setNewCheckMarkList] = useState(null)
  const [dkiLoaded, setDkiLoaded] = useState(false)
  const [dkiActive, setDkiActive] = useState(false)

  // DKI title logic
  const titleObj = primary.title.richText
  const parsedHeading =
    primary.title.richText &&
    primary.title.richText[0] &&
    primary.title.richText[0].text &&
    primary.title.richText[0].text.split('for ')
  const modifiedTitle = <RichText render={newHeading || titleObj} serializeHyperlink={GatsbyLink} />

  const checkmarkListObj = idx(primary, _ => _.checkmark_list.richText)
  const dkiListItem = <RichText render={newCheckMarkList || checkmarkListObj} serializeHyperlink={GatsbyLink} />

  useEffect(() => {
    const params = new URLSearchParams(typeof window !== 'undefined' && window.location.search)
    const dkiParam = params.get('dki')
    if (dkiParam && dkiParam.length > 0 && window.location.pathname === '/') {
      setDkiActive(true)
      let replacementText = dkiParam
      if (
        dkiParam.toLowerCase().includes('wcag') ||
        dkiParam.toLowerCase().includes('aoda') ||
        dkiParam.toLowerCase().includes('ada') ||
        dkiParam.toLowerCase().includes('gdpr')
      ) {
        replacementText = dkiParam.toUpperCase()
      } else {
        replacementText = toTitleCase(dkiParam)
      }
      dkiHeading = `${parsedHeading[0]}for ${replacementText}`

      if (dkiParam === 'aoda' || dkiParam === 'AODA') {
        if (idx(primary, _ => _.checkmark_list.richText)) {
          const modifiedCheckMarkList = primary.checkmark_list.richText.map(listItem => {
            if (listItem.text.includes('ADA')) {
              return {
                type: listItem.type,
                text: listItem.text.replace(/ADA/g, 'AODA'),
                spans: listItem.spans,
              }
            }
            return listItem
          })

          setNewCheckMarkList(modifiedCheckMarkList)
        }
      }

      setNewHeading([{ type: titleObj[0].type, text: dkiHeading, spans: titleObj[0].spans }])
      setDkiLoaded(true)
    } else {
      setDkiLoaded(true)
    }
  }, [dkiLoaded])

  return (
    <BannerContainer className={noShadow ? '' : 'skip'} backgroundColor={backgroundColor} id={anchorId || null}>
      <BannerWrapper
        hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
        hasBottomPadding={hasBottomPadding === null ? true : hasBottomPadding}
      >
        <InfoWrapper>
          {smallCapsText && smallCapsText.props.render[0] && (
            <Text font="smallCaps" richText color="purple500">
              {smallCapsText}
            </Text>
          )}
          {modifiedTitle && (
            <TitleText
              dkiActive={dkiActive}
              dkiLoaded={dkiLoaded}
              font="heading1"
              style={{ marginBottom: '1.45rem' }}
              richText
            >
              {modifiedTitle}
            </TitleText>
          )}
          {text && idx(text, _ => _.props.render[0].text) && (
            <BannerText font="body" richText>
              {text}
            </BannerText>
          )}
          {dkiListItem && idx(dkiListItem, _ => _.props.render[0].text) && (
            <CheckMarkListWrapper dkiActive={dkiActive} dkiLoaded={dkiLoaded} richText>
              {dkiListItem}
            </CheckMarkListWrapper>
          )}
          {primaryButtonText || secondaryButtonText ? (
            <>
              <Space height={24} tabletHeight={16} />
              <ButtonWrapper>
                {primaryButtonText && <Button text={primaryButtonText} to={primaryButtonLink} className="primary" />}
                <Space height={16} width={16} tabletHeight={12} mobileWidth={0} mobileHeight={0} />
                {secondaryButtonText && (
                  <Button
                    buttonType="secondary"
                    text={secondaryButtonText}
                    to={secondaryButtonLink}
                    className="secondary"
                  />
                )}
                <Space height={8} mobileHeight={0} />
              </ButtonWrapper>
            </>
          ) : (
            <Space tabletHeight={32} mobileHeight={0} />
          )}
        </InfoWrapper>
        {(modalVideo || imageSrc || lottieAnim) && (
          <ImageWrapper showImgMob={showImgMob}>
            {modalVideo ? (
              <a
                tabIndex="0"
                role="button"
                aria-label="Play AudioEye video opens in a modal"
                onKeyDown={e => {
                  if (e.key === 'Enter') setOpen(true)
                }}
                onClick={() => modalVideo && setOpen(true)}
              >
                {getImage(primary.image, Image, { showImgMob })}
              </a>
            ) : lottieAnim && typeof lottieAnim === 'object' ? (
              <LottieWrapper showImgMob={showImgMob}>
                <LottieAnimation lotti={lottieAnim} lottieAriaLabel={lottieAriaLabel} />
              </LottieWrapper>
            ) : (
              getImage(primary.image, Image, { showImgMob, loading: 'eager' })
            )}
          </ImageWrapper>
        )}
        {modalVideo && (
          <Modal
            open={(modalVideo && open) === true}
            onClose={() => modalVideo && setOpen(false)}
            aria-label="Video modal"
          >
            <ModalWrapper>
              <CloseIcon
                tabIndex="0"
                src={CrossIcon}
                aria-label="close"
                role="button"
                className="focus-outline"
                onClick={() => modalVideo && setOpen(false)}
                onKeyDown={e => {
                  if (e.key === 'Enter') setOpen(false)
                }}
              />
              <VimeoIframe isModal allowFullScreen video={modalVideo} autoPlay={false} />
            </ModalWrapper>
          </Modal>
        )}
      </BannerWrapper>
    </BannerContainer>
  )
}

export default BannerTitle

export const query = graphql`
  fragment BannerTitle on PrismicPageDataBodyBannerTitle {
    ...SliceBase
    id
    primary {
      anchor_id
      has_top_padding
      has_bottom_padding
      show_img_mobile
      background_color
      modal_video {
        link_type
        ...LinkContent
      }
      secondary_button_text {
        ...TextContent
      }
      secondary_button_link {
        ...LinkContent
      }
      primary_button_text {
        ...TextContent
      }
      primary_button_link {
        ...LinkContent
      }
      small_caps_text {
        ...TextContent
      }
      title {
        ...TextContent
      }
      text {
        ...TextContent
      }
      checkmark_list {
        ...TextContent
      }
      animation {
        ...TextContent
      }
      animation_aria_label
      image {
        alt
        url
        gatsbyImageData(width: 1080)
      }
    }
  }
`
